
























import Vue from "vue";
import { get_color_by_name } from "@/utils/colors";
import { format_overall_score } from "@/utils/global";

export default Vue.extend({
  name: "SkillMatch",
  data() {
    return {
      score: 0
    };
  },
  props: {
    matched_score: {
      type: Number,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.score = format_overall_score(this.matched_score.toString());
  },
  methods: {
    transform_source(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute("fill", get_color_by_name("primary"));
      return icon;
    }
  }
});
