





















import Vue, { PropType } from "vue";
import { get_color_by_name } from "@/utils/colors";
import { Candidates } from "@/interfaces/data_objects/candidates";

export default Vue.extend({
  name: "UserExperience",
  data() {
    return {
      experience: "0 Year"
    };
  },
  props: {
    profile: {
      type: Object as PropType<Candidates.CandidateDetails>,
      required: true
    }
  },
  created() {
    // If user profile & work history detailed key exist
    if (this.profile.profile && this.profile.profile.total_experience) {
      this.experience = this.profile.profile.total_experience;
    }
  },
  methods: {
    transform_source(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute("fill", get_color_by_name("success"));
      return icon;
    }
  }
});
